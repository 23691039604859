import NavigationRefresh from '@material-ui/icons/Refresh';
import React, { Fragment } from 'react';
import {
  ArrayField,
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  Link,
  ListButton,
  ListContext,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  UrlField,
  useList,
  useNotify,
  usePermissions,
} from 'react-admin';

import { Button, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { omit } from 'lodash';
import requester from '../../tools/requester';

import config from '../../config';

// Custom
import BlacklistList from '../Blacklist';
import CashbackList from '../Cashback';
import {
  LayoutTitle, SectionTitle, TooltipField,
} from '../Shared/Layout';
import CustomFlag from '../Shared/Layout/CustomFlag';
import VoucherList from '../Voucher';
import ConfigShowModal from './ConfigModal';

import { ActionsTypes, PropTypes } from '../../tools/types';

const { prismic } = config.services;
const searchUrl = prismic && prismic.shopSearchUrl ? prismic.shopSearchUrl : null;

const amountField = (record) => record.cashback && `${record.cashback.amount} ${record.cashback.type === 'PERCENTAGE' ? '%' : record.cashback.currency}`;
const renderConditionsEdit = (record) => (
  <a href={`${searchUrl}${record.domain}`} target="_blank" rel="noopener noreferrer">{`${searchUrl}${record.domain}`}</a>
);
const renderTotalSavings = (record) => (record.stats ? `${record.stats.savingsTotal} ${record.stats.currencySavingsTotal}` : '');

const renderShopCountry = (record) => {
  if (record.country) {
    return (
      <CustomFlag isoCode={record.country.iso2a || record.country} />
    );
  }
  return 'Pays non défini';
};

function HistoriesPagination(props) {
  return <Pagination {...props} rowsPerPageOptions={[10, 20, 50, 100]} />;
}

/**
 * shopShow
 */
function ShopShow(props) {
  const ownProps = { ...omit(props, ['hasList', 'hasShow', 'hasEdit', 'hasCreate']) };
  const { id } = ownProps;
  const { permissions } = usePermissions();
  const notify = useNotify();

  return (
    <Show
      title={<LayoutTitle source="domain" />}
      actions={<ShopActions notify={notify} ownId={id} permissions />}
      {...ownProps}
    >
      <TabbedShowLayout>
        <Tab label="Résumé">
          <TextField source="id" />

          <FunctionField
            label="Pays"
            render={renderShopCountry}
          />
          <TextField source="domain" label="Domaine" />
          <TextField source="space.pattern" label="Pattern" />
          <BooleanField source="space.default" label="Espace par défaut" />
          <UrlField source="url" />

          <SectionTitle label="Cashback" />
          <BooleanField source="cashbackActivated" label="Activé" />
          <TextField source="cashbackActivatedComment" label="Commentaire" />
          <FunctionField
            label="Editer les conditions:"
            render={renderConditionsEdit}
          />

          <SectionTitle label="Comportement des coupons" />

          <TooltipField title="Activer ou désactiver le testeur de codes promo sur ce site" placement="top-start">
            <TextField source="voucherAccess" />
          </TooltipField>

          <TooltipField title="Description du comportement du testeur de code : rechargement de page, message de refus de code…" placement="top-start">
            <TextField source="voucherBehavior" />
          </TooltipField>

          <SectionTitle label="" />
          <BooleanField source="alertEnabled" label="Alerte nouveau coupon" />
          <DateField source="created" showTime locales="fr-FR" />
          <DateField source="updated" showTime locales="fr-FR" />
        </Tab>
        <Tab label="Stats" path="stats">
          <FunctionField label="Savings total" render={renderTotalSavings} />
          <NumberField source="stats.vouchersCount" label="Nombre de coupon" />
          <DateField source="stats.voucherMostRecent" locales="fr-FR" label="Dernier coupon créé le" />
          <BooleanField source="stats.hasExclusiveVoucher" label="Coupon exclusif" />
        </Tab>
        <Tab label="Programs" path="programs">
          <ReferenceManyField
            {...props}
            sort={props.location.search.includes('isShopNetwork') ? { field: 'status', order: 'ASC' } : {}}
            addLabel={false}
            reference="programs"
            target="shopId"
            perPage={20}
            pagination={<HistoriesPagination />}
          >
            <Datagrid>
              <NumberField source="priority" sortable={false} />
              <TextField source="name" label="Nom" sortable={false} />

              <TextField source="networkInternalId" sortable={false} />
              <TextField source="network.name" label="Network" sortable={false} />

              <TextField source="type" sortable={false} />
              <TextField source="status" />

              <ShowButton basePath="/programs" sortable={false} />
              <EditButton basePath="/programs" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Comparator" path="comparator">
          <ArrayField source="configsComparator" reference="configsComparator" addLabel={false}>
            <Datagrid>
              <BooleanField source="active" label="Active" />
              <DateField source="created" locales="fr-FR" label="Créé le" />
              <DateField source="modified" locales="fr-FR" label="Modifié le" />
              <ConfigShowModal />
              <EditLink id={id} isComparator />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Configs" path="configs">
          <SectionTitle label="Desktop" />
          <ArrayField source="configs" reference="configs" addLabel={false}>
            <Datagrid>
              <BooleanField source="active" label="Active" />
              <TextField source="createdBy" label="Créé par" />
              <DateField source="created" locales="fr-FR" label="Créé le" />
              <DateField source="modified" locales="fr-FR" label="Modifié le" />
              <ConfigShowModal />
              <EditLink id={id} />
            </Datagrid>
          </ArrayField>
          <SectionTitle label="Mobile" />
          <ArrayField source="configsMobile" reference="configsMobile" addLabel={false}>
            <Datagrid>
              <BooleanField source="active" label="Active" />
              <TextField source="createdBy" label="Créé par" />
              <DateField source="created" locales="fr-FR" label="Créé le" />
              <DateField source="modified" locales="fr-FR" label="Modifié le" />
              <ConfigShowModal />
              <EditLink id={id} />
            </Datagrid>
          </ArrayField>
        </Tab>
        {permissions && permissions.vouchers.read && (
          <Tab label="Coupons" path="vouchers">
            <VoucherList {...ownProps} shopId={id} />
          </Tab>
        )}
        {permissions && permissions.blacklists.read && (
          <Tab label="Blacklists" path="blacklists">
            <BlacklistList {...ownProps} permissions={permissions} shopId={id} />
          </Tab>
        )}
        {permissions && permissions.shops.cashbacks.read && (
          <Tab label="Cashbacks" path="cashbacks">
            <SectionTitle label="Meilleur cashback (inclus SharePolicy)" />
            <FunctionField label="Montant" render={amountField} />
            <TextField source="cashback.url" label="Url" />
            <TextField source="cashback.threshold" label="Threshold" />
            <ProgramsCashbackList {...ownProps} />
          </Tab>
        )}
        {permissions && permissions.shops.cashbacks.read && (
          <Tab label="Cashback Autodisplay Patterns" path="cashback-autodisplay-patterns">
            <CashbackAutodisplayPatternList {...ownProps} />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
}

ShopShow.propTypes = {
  id: PropTypes.string,
  record: PropTypes.object,
  location: PropTypes.object,
};

const ProgramsCashbackList = (props) => {
  const { record } = props;

  return record.programs.map((program) => {
    record.programId = program.id;
    const updatedProps = {
      ...props,
      record: {
        ...record,
        programId: program.id,
      },
      source: 'programId',
    };

    return (
      <Fragment key={program.id}>
        <Typography className="section-title" style={{ marginTop: '1rem' }} variant="subtitle1">
          <Link to={`/programs/${program.id}/show`}>
            {`${program.name} - ${program.network.name} (${program.status})`}
          </Link>
        </Typography>
        <CashbackList {...updatedProps} reference="shops/cashbacks" programId={program.id} />
      </Fragment>
    );
  });
};

function CashbackAutodisplayPatternList({ record, location }) {
  const { permissions } = usePermissions();
  const listContext = useList({
    data: record.cashbackAutodisplayPatterns,
    ids: record.cashbackAutodisplayPatterns.map((p) => p.id),
    loaded: location?.state?.loaded,
  });

  return (
    <>
      <TopToolbar>
        {permissions && permissions.shops.cashbacks.write && (
          <CreateButton
            to={{
              pathname: '/cashback-autodisplay-patterns/create',
              search: `?idShop=${record.id}`,
            }}
          />
        )}
      </TopToolbar>
      <ListContext.Provider value={listContext}>
        <Datagrid>
          <TextField source="pattern" label="Pattern" />
          <EditButton basePath="/cashback-autodisplay-patterns" />
        </Datagrid>
      </ListContext.Provider>
    </>
  );
}

CashbackAutodisplayPatternList.propTypes = {
  record: PropTypes.object,
  location: PropTypes.object,
};

function ShopActions({
  basePath, data, notify, ownId, permissions,
}) {
  const handleClick = async () => {
    requester(`${config.services.backend.endpoint}/shops/${ownId}/clearDomainSpecCache`, { method: 'post' })
      .then(() => notify("All domainSpecs for this shop were removed from extension-backend cache ! Don't forget to also clean your local storage 😉", { type: 'success', autoHideDuration: 5000 }))
      .catch((err) => notify(`Could not clear extension cache for some reason 😞: ${err}`, { type: 'error', autoHideDuration: 5000 }));
  };

  const hasPermissionForClear = permissions === true || permissions?.write;

  return (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
      {hasPermissionForClear && (
        <Button
          startIcon={<NavigationRefresh />}
          onClick={handleClick}
          size="small"
          color="primary"
          title="immediately clear this shop's domainSpec from extension backend's cache"
        >
          Clear extension cache
        </Button>
      )}
  </TopToolbar>
  );
}

ShopActions.propTypes = {
  ...ActionsTypes.Show,
  notify: PropTypes.func,
  ownId: PropTypes.string,
  permissions: PropTypes.object,
};

function EditLink({ basePath, id, isComparator }) {
  return (
  <Button size="small" color="primary" href={`#${basePath}/${id}/${isComparator ? 'comparator' : 'configs'}`}>
      <EditIcon style={{ fontSize: '14px' }} />
      {' Edit'}
  </Button>
  );
}

EditLink.propTypes = {
  id: PropTypes.string,
  basePath: PropTypes.string,
  isComparator: PropTypes.boolean,
};

export default ShopShow;
